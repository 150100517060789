//==============================================================================
// Extended version of global state's get-cart-state action
//
// The base version will check for the checkout cart cookie. If it exists, that
// cart will be loaded. Otherwise, it will copy the regular cart to a new cart.
//
// This version will maintain standard functionality unless a specific query
// parameter exists in the URL.
// If the query parameter exists, a new cart will be constructed with products
// from the query string.
// It would be better to return an empty cart and leave cart population to
// external code, but an empty cart will cause checkout to redirect.
// Intercepting and adding contents before the redirect would be messier than
// adding cart construction to this data action.
//
// NOTES:
//   This doesn't currently support catalogs. We would need to add the catalog to the URL somehow.
//   If we're adding discounts or catalogs, consider encoding the URL for light-weight protection
//==============================================================================
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings,
    ICreateActionContext,
} from '@msdyn365-commerce/core';
import { Cart, CartType } from '@msdyn365-commerce/retail-proxy';
import { getCartState } from '@msdyn365-commerce/global-state';
import { copyAsync, readAsync, createCartAsync, addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

//==============================================================================
// INTERFACES
//==============================================================================
interface ProductAddRequest {
    recId: number
    quantity: number
}

//==============================================================================
// INPUT
//==============================================================================

//==========================================================
// Input class for getCheckoutCart data action.
//==========================================================
export class GetCheckoutCartInput implements IActionInput {
    private readonly apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => `CheckoutCart-chanId:${this.apiSettings.channelId}-catId:${this.apiSettings.catalogId}`;

    public getCacheObjectType = () => 'CheckoutCart';

    public dataCacheType = (): CacheType => 'request';
}

//==========================================================
//==========================================================
const createInput = (inputData: ICreateActionContext) => {
    return new GetCheckoutCartInput(inputData.requestContext.apiSettings);
};

//==============================================================================
// ACTION
//==============================================================================
enum CartTokenPrefix {
    Auth = 't',
    Anon = 'p'
}

//==========================================================
//==========================================================
export async function getCheckoutCart(input: GetCheckoutCartInput, ctx: IActionContext): Promise<Cart | undefined> {

    // Determine whether to use the standard version, or the self-populating version
    // @TODO/dg: Make the query param case-insensitive
    const useSelfPopulating = !!(ctx.requestContext.query?.cart);
    return useSelfPopulating ? getPopulatedCheckoutCart(input, ctx) : standardGetCheckoutCart(input, ctx);
}

//==========================================================
// Stand-alone Version
//==========================================================
async function getPopulatedCheckoutCart(input: GetCheckoutCartInput, ctx: IActionContext): Promise<Cart | undefined> {

    // Parse the request
    const cartRequest = parseCartRequest(ctx.requestContext.query!.cart);

    // Ensure there's at least a single product in the request
    if (cartRequest.length > 0) {

        // Create an empty checkout cart
        let cart = await createCartAsync({ callerContext: ctx, bypassCache: 'none' }, {CartTypeValue: CartType.Checkout} as Cart);

        // Add items to the cart
        cart = await addCartLinesAsync({ callerContext: ctx}, cart.Id, cartRequest.map(entry => ({
            CatalogId: ctx.requestContext.apiSettings.catalogId,        // @FIXME/dg: Improve this
            EntryMethodTypeValue: 3,    // @FIXME/dg: Set this by name (which name??)
            ProductId: entry.recId,
            Quantity: entry.quantity,
        })));

        return cart;
    }

    return undefined;
}

//==========================================================
// Parse requests passed in via query parameter
//
// Format: recId:qty,recId:qty,...
//==========================================================
function parseCartRequest(request: string): ProductAddRequest[] {

    // Split the string into a series of item/quantity pairs and process each one
    return request.split(',').reduce(
        (output: ProductAddRequest[], entry) => {

            // Split item record ID and quantity
            const [recId, qty] = entry.split(':');

            // Add to the list if the rec ID and quantity are valid
            if (+recId && +qty) {
                output.push({
                    recId: +recId,
                    quantity: +qty,
                });
            }

            return output;
        },
        []
    );
}

//==========================================================
// LEGACY VERSION: Data action to copy the cart for checkout actions.
//==========================================================
async function standardGetCheckoutCart(input: GetCheckoutCartInput, ctx: IActionContext): Promise<Cart | undefined> {
    const savedCheckoutCartId = ctx.requestContext.cookies.getCheckoutCartCookie();
    const cartCookieParts: string[] = savedCheckoutCartId.split(':');
    let checkoutCartId = null;
    const isAuthenticated = ctx.requestContext.user.isAuthenticated;

    if (
        (isAuthenticated && cartCookieParts[0] === CartTokenPrefix.Auth) ||
        (!isAuthenticated && cartCookieParts[0] === CartTokenPrefix.Anon)
    ) {
        checkoutCartId = cartCookieParts[1];
    }

    let checkoutCart: Cart | undefined;

    if (checkoutCartId) {
        try {
            checkoutCart = await readAsync({ callerContext: ctx, bypassCache: 'none' }, checkoutCartId);
        } catch (error) {
            ctx.telemetry.error('Error getting checkout cart based on saved checkout card id');
            ctx.telemetry.exception(error as Error);
        }
    }

    const cartState = await getCartState(ctx);
    if (cartState && cartState.cart.Id !== undefined) {
        if (checkoutCart && checkoutCart.Version && cartState.cart.Version && checkoutCart.Version > cartState.cart.Version) {
            return Promise.resolve(checkoutCart);
        }

        return copyAsync({ callerContext: ctx }, cartState.cart.Id, 2)
            .then(cart => {
                ctx.requestContext.cookies.setCheckoutCartCookie(cart, isAuthenticated);
                return cart;
            })
            .catch(error => {
                ctx.telemetry.error('Error copying cart');
                ctx.telemetry.exception(error);
                return undefined;
            });
    }

    return undefined;
}

//==============================================================================
// DEFINITION
//==============================================================================
export const getCheckoutCartDataAction = createObservableDataAction({
    id: '@msdyn365-commerce/global-state/get-checkout-cart',
    action: <IAction<Cart | undefined>>getCheckoutCart,
    input: createInput
});

export default getCheckoutCartDataAction;
